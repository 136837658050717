<template>
	<div id="container" class="map" />
</template>

<script>
import AMap from 'AMap' // 引入高德地图
export default {
	props: {
		longitude: {
			type: String,
			default: '116.397428',
		},
		latitude: {
			type: String,
			default: '39.90923',
		},
	},
	data() {
		return {}
	},
	mounted() {
		this.init1()
	},
	methods: {
		init1() {
			const map = new AMap.Map('container', {
				center: [this.longitude, this.latitude],
				resizeEnable: false, // 是否监控地图容器尺寸变化，默认值为false
				zoom: 12,
			})

			// 创建 AMap.Icon 实例：
			var icon = new AMap.Icon({
				size: new AMap.Size(90, 90), // 图标尺寸
				image: require('@/assets/imgs/alert.png'),
				imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
				imageSize: new AMap.Size(60, 60), // 根据所设置的大小拉伸或压缩图片
			})

			// 将 Icon 实例添加到 marker 上:
			var marker = new AMap.Marker({
				position: new AMap.LngLat(this.longitude, this.latitude),
				offset: new AMap.Pixel(-10, -10),
				icon: icon, // 添加 Icon 实例
				title: '风险事件',
				zoom: 17,
			})

			map.add(marker)
		},
	},
}
</script>

<style></style>
